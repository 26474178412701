import React from 'react';
import classes from './AwaitingPayment.module.scss';
import { DollarOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { useTranslation } from 'react-i18next';
import RowLoader from 'components/Sceletons/RowLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchRoom } from 'store/reducers/roomsCreator';
import { setUserAndRoomStatus } from 'store/slices/userDataSlice';

const { Link } = Typography;

const AwaitingPayment = () => {
  const { dataRoom, isLoadingRooms } = useAppSelector((store) => store.dataRoom);
  const { isLoadingUserData } = useAppSelector((store) => store.userData);  

  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    params.room && dispatch(fetchRoom({ id: params.room }));
  }, [])

  React.useEffect(() => {
    if (dataRoom?.tariff_lifetime_to !== null) {
      dataRoom && navigate(`/room/${dataRoom?.id}/documents`);
    }
  }, [dataRoom])

  return (
    <div className={classes.infoWrap}>
      <div className={classes.info}>
        <DollarOutlined className={classes.paymentIcon}/>
        <div className={classes.text}>
          <p>{t('awaitingPayment.text')}</p>
        </div>
        <div className={classes.actions}>
          {(isLoadingRooms || isLoadingUserData)
            ? <RowLoader width='200px' padding='2px 0 0 0' height='27px' />
            : (dataRoom?.tariff?.last_payment_intent.link && <Link className={classes.primary} href={dataRoom?.tariff?.last_payment_intent.link}>
              {t('awaitingPayment.link')}
            </Link>)
          }
        </div>
      </div>
    </div>
  );
}

export default AwaitingPayment;
