import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserData, IRoomPermissions, IDataRoom } from 'interfaces';
import { fetchUserData, fetchUserPermissions } from 'store/reducers/userDataCreator';

type statusTypes = '' | 'pendingUserData' | 'emptyDataRoom' | 'fulfilledUserData' | 'fulfilledDataRoom';
export const adminPermissions = {
  can_view_logs: true,
  can_edit_roles: true,
  can_edit_room_settings: true,
  can_edit_folder_permissions: true,
  can_edit_agreement: true,
  can_create_group_all_roles: true,
  can_create_group_only_users: false,
  can_invite_users: true,
  can_edit_user_group: true,
  can_create_folder: true,
  can_exclude_users: true,
  can_delete_room: true,
  can_manage_signing: true
}

interface DataState {
  userData: IUserData | null;
  isLoadingUserData: boolean;
  errorUserData: string;
  selectedRoom?: IDataRoom;
  canAvailableNavigate: boolean;

  userPermissions: IRoomPermissions;
  isLoadingUserPermissions: boolean;
  errorUserPermissions: string;
  userAndRoomStatus: statusTypes;
}

const initialState: DataState = {
  userData: null,
  isLoadingUserData: false,
  canAvailableNavigate: false,
  errorUserData: '',

  userPermissions: {
    can_view_logs: false,
    can_edit_roles: false,
    can_edit_room_settings: false,
    can_edit_folder_permissions: false,
    can_edit_agreement: false,
    can_create_group_all_roles: false,
    can_create_group_only_users: false,
    can_invite_users: false,
    can_edit_user_group: false,
    can_create_folder: false,
    can_exclude_users: false,
    can_delete_room: false,
    can_manage_signing: false,
  },
  isLoadingUserPermissions: false,
  errorUserPermissions: '',
  userAndRoomStatus: 'pendingUserData',
};

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<IUserData | null>) {
      state.userData = action.payload;
      state.errorUserPermissions = '';
    },
    setUserAvailableRooms(state, action: PayloadAction<IDataRoom[]>) {
      if (state.userData) {
        state.userData.available_rooms = action.payload;
      }
    },
    resetUserPermissions(state) {
      state.userPermissions = {
        can_view_logs: false,
        can_edit_roles: false,
        can_edit_room_settings: false,
        can_edit_folder_permissions: false,
        can_edit_agreement: false,
        can_create_group_all_roles: false,
        can_create_group_only_users: false,
        can_invite_users: false,
        can_edit_user_group: false,
        can_create_folder: false,
        can_exclude_users: false,
        can_delete_room: false,
        can_manage_signing: false,
      }
    },
    setSelectedDataRoom(state, action: PayloadAction<IDataRoom | undefined>){
      state.selectedRoom = action.payload;
    },
    setUserAndRoomStatus(state, action: PayloadAction<statusTypes>){      
      state.userAndRoomStatus = action.payload;
    },
    setRooms(state, action: PayloadAction<IDataRoom[]>) {
      if(state.userData){
        state.userData.available_rooms = action.payload;
      }
    },
    setDefaultUserPermissions(state){
      state.userPermissions = adminPermissions;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.fulfilled, (state, action: PayloadAction<IUserData>) => {
      const lastRoomId = localStorage.getItem('previousRoomId') || '';
      const lastRoomData = action.payload.available_rooms.find(room => room.id === lastRoomId);

      state.isLoadingUserData = false;
      state.errorUserData = '';
      state.userData = action.payload;
      state.selectedRoom = lastRoomData;

      if(!action.payload.available_rooms[0]){
        state.userAndRoomStatus = 'emptyDataRoom';
        return;
      };

      state.userAndRoomStatus = 'fulfilledUserData';
    })
    builder.addCase(fetchUserData.pending, (state) => {
      state.isLoadingUserData = true;
      state.canAvailableNavigate = true;
    })
    builder.addCase(fetchUserData.rejected, (state, action: any) => {      
      state.isLoadingUserData = false;
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.errorUserData = action.payload
      } else {
        state.errorUserData = action.error.message
      }
    })

    builder.addCase(fetchUserPermissions.fulfilled, (state, action: PayloadAction<IRoomPermissions>) => {
      state.userPermissions = action.payload;
      state.isLoadingUserPermissions = false;
      state.errorUserPermissions = '';
    })
    builder.addCase(fetchUserPermissions.pending, (state) => {
      state.isLoadingUserPermissions = true;
    })
    builder.addCase(fetchUserPermissions.rejected, (state, action: any) => {
      state.isLoadingUserPermissions = false;
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.errorUserPermissions = action.payload.errorMessage
      } else {
        state.errorUserPermissions = action.error.message
      }
    })
  }
});

const userReducer = userDataSlice.reducer;

export const {
  setUserData,
  setUserAvailableRooms,
  resetUserPermissions,
  setSelectedDataRoom,
  setUserAndRoomStatus,
  setRooms,
  setDefaultUserPermissions,
} = userDataSlice.actions;
export default userReducer;
