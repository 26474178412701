import React from 'react';
import classes from './TariffsComponent.module.scss';
import { Button, Segmented, Select, Switch, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PaymentFlowSelection from 'components/Modals/PaymentFlowSelection/PaymentFlowSelection';
import { IColumn } from 'interfaces';
import { tariffsColumns } from './tariffsColumns';
import { costWithSale, ITariff, StorageValues, tariffsCost, tariffsData, Tarrifs } from './tariffsData';
import { CheckOutlined, CloseOutlined, WechatOutlined } from '@ant-design/icons';
import colors from '../../scss/variables/colors.module.scss';
import { useAppSelector } from 'store/hook';
import { NewRoomModal } from 'components/Modals';
import { languagesValues } from 'components/Header/HeaderComponent';

// @ts-ignore
const contactUsAdress = window.REACT_APP_CONTACT_US !== 'REPLACE_REACT_APP_CONTACT_US' ? window.REACT_APP_CONTACT_US : process.env.REACT_APP_CONTACT_US || 'https://demo.tazadeal.com/contact';
const publicFunctions = ['number_of_admins', 'nda_opportunity', 'water_marks', 'document_encryption', 'documents_approval', 'documents_analytics', 'chat_rooms', 'mobile_application'];

const iconCheck = <CheckOutlined  style={{fontSize: 16, color: colors.excel, paddingRight: 5}}/>;
const iconClose = <CloseOutlined  style={{fontSize: 16, color: colors.red, paddingRight: 5}}/>;
const iconCheckGray = <CheckOutlined  style={{fontSize: 16, color: colors.textcolor, paddingRight: 5}}/>;

type TariffsComponentProps = {
  isProtected?: boolean;
};

export type ChoosePayment = {
  isOpenNewRoomModal: boolean;
  isChoosePaymentFlow?: boolean;
  tariff: ITariff | null;
}

const TariffsComponent = ({isProtected}: TariffsComponentProps) => {
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);
  const [dataSource, setDataSource] = React.useState<any>([]);
  const [forAYear, setForAYear] = React.useState(false);
  const [newRoomState, setNewRoomState] =
    React.useState<ChoosePayment>({isOpenNewRoomModal: false, isChoosePaymentFlow: false, tariff: null});

  const formTariff = (tariff_name: Tarrifs, cost: number, storage: number, members: number) => ({
    tariff_name: tariff_name,
    tariff_cost: `${String(cost * (forAYear ? 12 : 1))}`,
    tariff_duration: 1,
    tariff_duration_unit: forAYear ? 'YEAR' : 'MONTH',
    tariff_constraints: {
      storage: storage,
      members: members
    }
  })

  const { userData, isLoadingUserData } = useAppSelector((store) => store.userData);

  const defaultLanguage = localStorage.getItem('language') || 'en';
  const { i18n, t } = useTranslation();
  
  const onLanguageChange = (value: string) => {
    localStorage.setItem('language', value);
    i18n.changeLanguage(value);
  };

  React.useEffect(()=> {
    setDataSource(tariffsData);

    return () => {
      localStorage.removeItem('ended_tariff_lifetime');
    }
  },[]);

  React.useEffect(()=> {    
    const customColumns = tariffsColumns.map((col: IColumn) => ({
      ...col,
      render: (value: any, info: any) => renderCell({value, info, col}),
    }))
    setModifiedColumns(customColumns);
  },[i18n.language, dataSource, forAYear, userData]);

  const getTariffByName = (tariffName: Tarrifs) => {
    const rightIndexStorage = tariffsData.findIndex(el => el.key === 'storage_capacity');
    const storage: StorageValues = dataSource[rightIndexStorage].current_capacity[tariffName];

    const rightIndexMembers = tariffsData.findIndex(el => el.key === 'number_of_users');
    const members = dataSource[rightIndexMembers][tariffName];
    
    const cost = forAYear
      ? costWithSale[tariffName as Tarrifs]?.[storage as StorageValues]
      : tariffsCost[tariffName as Tarrifs]?.[storage as StorageValues];

    return formTariff(tariffName, Number(cost), Number(storage), members);
  }

  const openRightModal = (tariffName: Tarrifs) => {
    Boolean(localStorage.getItem('ended_tariff_lifetime')) && tariffName !== Tarrifs.Trial
      ? setNewRoomState({isOpenNewRoomModal: false, isChoosePaymentFlow: true, tariff: getTariffByName(tariffName)})
      : setNewRoomState({isOpenNewRoomModal: true, isChoosePaymentFlow: false, tariff: getTariffByName(tariffName)})
  }

  const closeModals = () => {
    setNewRoomState({isOpenNewRoomModal: false, isChoosePaymentFlow: false, tariff: null});
  }

  const renderCell = (props: any) => {
    const { value, info, col } = props;

    if(info.key === 'tariff_name') {
      return <div className={classes.tariff_name}>
        {t(`Tariffs.${info.key}.${col.key}`)}
      </div>
    };

    if(info.key === 'price') {
      const rightIndex = tariffsData.findIndex(el => el.key === 'storage_capacity');
      const price = tariffsCost[col.key as Tarrifs]?.[tariffsData[rightIndex].current_capacity[col.key] as StorageValues];
      const priceWithSale = costWithSale[col.key as Tarrifs]?.[tariffsData[rightIndex].current_capacity[col.key] as StorageValues];

      switch(col.key) {
        case Tarrifs.Trial:
          return <div className={classes.price} style={{fontSize: 16, fontWeight: 500}}>{t('Tariffs.14_days')}</div>;
        case Tarrifs.Enterprise:
          return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <WechatOutlined style={{fontSize: 36, fontWeight: 500, paddingRight: 10 }}/>
              <span>{t('Tariffs.custom_price')}</span>
            </div>
          );
        default: 
          return (
            <div className={classes.price}>
              {forAYear && <span style={{paddingRight: 5, textDecoration: 'line-through'}}>$ {price}{t(`Tariffs.${info.key}`)}</span>}
              <span style={{fontSize: 18, fontWeight: 500}}>$</span>
              <span style={{fontSize: 18, fontWeight: 500}}> {forAYear ? priceWithSale : price}</span>
              {t(`Tariffs.${info.key}`)}
              {forAYear && <div className={classes.forAYear}>
                {t(`Tariffs.payingAnnually`)}
              </div>}
            </div>
          );
      };
    };

    if(info.key === 'number_of_users') {
      return <div className={classes.price} style={{fontWeight: 500}}>
        {t(`Tariffs.${info.key}_${value}`)}
      </div>
    };

    if(info.key === 'by_it' || info.key === 'by_it_2') {
      switch(col.key) {
        case Tarrifs.Trial:
          return (
            <Tooltip title={t('Tariffs.onceAYear')}>
              <Button
                disabled={
                  !userData?.can_take_trial ||
                  isLoadingUserData}
                loading={isLoadingUserData}
                style={{width: '100%'}}
                type='primary'
                onClick={() => openRightModal(Tarrifs.Trial)}
              >
                {t(`Tariffs.try_free`)}
              </Button>
            </Tooltip>
          );
        case Tarrifs.Enterprise:
          return <Button
              style={{width: '100%'}}
              type='primary'
              onClick={() => window.location.href = contactUsAdress}
            >
              {t(`Tariffs.contact_us`)}
            </Button>;
        default: 
          return <Button
              style={{width: '100%'}}
              type='primary'
              disabled={isLoadingUserData}
              loading={isLoadingUserData}
              onClick={() => openRightModal(col.key)}

            >
              {`${t(`Tariffs.buy`)} ${t(`Tariffs.tariff_name.${col.key}`)}`}
            </Button>;
      };
    }

    if (info.key === 'storage_capacity') {
      const options = value.map((el: any) => ({
        label: `${el} ${t('Tariffs.gb')}`,
        value: el,
      }))
      return <div className={classes.storage_capacity}>
        {t(`Tariffs.${info.key}`)}
        {value[0] 
          ? <Segmented
              className={classes.segmentedStyle}
              value={info.current_capacity.value}
              onChange={(value) => {
                const newDataSource=[...dataSource];
                const rightIndex = tariffsData.findIndex(el => el.key === 'storage_capacity');
                newDataSource[rightIndex].current_capacity[col.key] = value;                
                setDataSource(newDataSource);      
              }}
              options={options}
              block
            />
          : <div style={{fontSize: 18, fontWeight: 500, paddingLeft: 6, textAlign: 'center'}}>{t(`Tariffs.on_request`)}</div>
        }
      </div>
    }

    if (info.key === 'suitable_for') {
      return <div className={classes.suitable_for}>
        <p style={{fontWeight: 500}}>{t(`Tariffs.${info.key}.${col.key}.${info.key}`)}</p>
        <p>{t(`Tariffs.${info.key}.${col.key}.1`)}</p>
        <p>{t(`Tariffs.${info.key}.${col.key}.2`)}</p>
        <p>{t(`Tariffs.${info.key}.${col.key}.3`)}</p>
        <p>{t(`Tariffs.${info.key}.${col.key}.4`)}</p>
        <p>{t(`Tariffs.${info.key}.${col.key}.5`)}</p>
      </div>
    }

    if(info.key === 'available_rooms') {
      switch(col.key) {
        case Tarrifs.Enterprise:
          return <div>{iconCheck}{t('Tariffs.unlimited_rooms')}</div>;
        default: 
          return <div>{iconCheck}{t(`Tariffs.one_room`)}</div>;
      };
    };

    if(info.key === 'preconfigured_roles') {
      return <div>{iconCheck}{t(`Tariffs.preconfigured_roles`)}</div>;
    };

    if(info.key === 'access_period') {
      const freeMonth = <div style={{paddingTop: 15}}>{iconCheck}{t(`Tariffs.free_save_month`)}</div>
      const current_period = (value: Tarrifs) => {
        switch(value) {
          case Tarrifs.Trial:
            return <div>{iconCheck}{t(`Tariffs.${info.key}.${value}`)}</div>;
          case Tarrifs.Enterprise:
            return <div>{iconCheck}{t(`Tariffs.${info.key}.${value}`)}</div>;
          default: 
            return <div>{iconCheck}{t(`Tariffs.${info.key}.default`)}</div>;
        };
      }
      return <>
        {current_period(col.key)}
        {freeMonth}
      </>
    };

    if(info.key === 'bulk_mailing') {
      return <>
        <div>{iconCheck}{t(`Tariffs.${info.key}`)}</div>
        <div style={{paddingTop: 15}}>{iconCheck}{t(`Tariffs.notifications_plus`)}</div>
      </>
    };

    if(info.key === '2FA') {
      return col.key === Tarrifs.Trial
        ? <div>{iconClose}{t(`Tariffs.${info.key}`)}</div>
        : <div>{iconCheck}{t(`Tariffs.${info.key}`)}</div>
    };

    if(info.key === 'SSO') {
      return col.key === Tarrifs.Trial || col.key === Tarrifs.Starter
        ? <div>{iconClose}{t(`Tariffs.${info.key}`)}</div>
        : <div>{iconCheck}{t(`Tariffs.${info.key}`)}</div>
    };

    if(info.key === 'user_activity_log') {
      return <div>{iconCheck}{t(`Tariffs.user_activity_log`)}</div>
    };

    if (publicFunctions.includes(info.key)) {
      return <div>{iconCheck}{t(`Tariffs.${info.key}`)}</div>
    }

    if(info.key === 'backup') {
      switch(col.key) {
        case Tarrifs.Trial:
          return <div>{iconClose}{t(`Tariffs.${info.key}`)}</div>
        case Tarrifs.Enterprise:
          return <div>{iconCheck}{t(`Tariffs.${info.key}`)}{t('Tariffs.on_request_()')}</div>;
        default: 
          return<div>{iconCheck}{t(`Tariffs.${info.key}`)}</div>;
      };
    };
    
    if(info.key === 'storage_after_payment') {
      return col.key === Tarrifs.Enterprise
        ? <div>{iconCheck}{t(`Tariffs.${info.key}`)}{t('Tariffs.on_request_()')}</div>
        : <div>{iconCheck}{t(`Tariffs.${info.key}`)}{t(`Tariffs.one_month`)}</div>
    };

    if(info.key === 'electronic_signature') {
      return (<div className={classes.electronic_signature}>
        <div style={{fontWeight: 500}}>
          {t(`Tariffs.${info.key}`)}
        </div>
        <div style={{paddingTop: 10}}>
          {iconCheck}{t(`Tariffs.SES`)}
        </div>
        {(col.key === Tarrifs.Enterprise || col.key === Tarrifs.Business)
          && (<>
            <div style={{paddingTop: 10}}>
              {iconCheckGray}{t(`Tariffs.AES`)}
            </div>
            <div style={{paddingTop: 10}}>
              {iconCheckGray}{t(`Tariffs.QES`)}
            </div>
          </>)
        }
      </div>)
    };

    if(info.key === 'additional_storage') {
      return col.key === Tarrifs.Trial
        ? <div>{iconClose}{t(`Tariffs.${info.key}`)}</div>
        : <div>{iconCheck}{t(`Tariffs.${info.key}`)}{t('Tariffs.on_request_()')}</div>
    };

    if(info.key === 'uploading_data') {
      switch(col.key) {
        case Tarrifs.Trial:
          return <div>{iconClose}{t(`Tariffs.${info.key}`)}</div>;
        case Tarrifs.Enterprise:
          return <div>{iconCheck}{t(`Tariffs.${info.key}`)}{t('Tariffs.on_request_()')}</div>;
        default: 
          return <div>{iconCheck}{t(`Tariffs.${info.key}`)}</div>;
      };
    };

    return null;
  };

  return (
    <div className={isProtected ? classes.protectedWrap : classes.triffsWrap}>
      <div className={classes.headerWrap}>
        <div className={classes.periodWrap}>
          <span>{t(`Tariffs.forAMonth`)}</span>
          <Switch disabled className={classes.switch} value={forAYear} onChange={setForAYear} />
          <span>{t(`Tariffs.forAYear`)}</span>
          <span className={classes.switchSale}>-20%</span>
        </div>
        {!isProtected && <Select
          defaultValue={defaultLanguage}
          onChange={onLanguageChange}
          style={{ minWidth: 130 }}
          options={languagesValues}
          optionLabelProp={'label'}
        />}
      </div>

      <Table
        columns={modifiedColumns}
        size='large'
        dataSource={dataSource}
        showHeader={false}
        className={classes.tariffsTable}
        pagination={false}
        rowHoverable={false}
        scroll={{
          x: '600px'
        }}
      />
      <div style={{height: 20}}></div>

      {newRoomState.isOpenNewRoomModal && (
        <React.Suspense fallback={<div />}>
          <NewRoomModal
            open={newRoomState.isOpenNewRoomModal}
            onCancel={closeModals}
            newRoomState={newRoomState}
          />
        </React.Suspense>
      )}

      {newRoomState.isChoosePaymentFlow && (
        <React.Suspense fallback={<div />}>
          <PaymentFlowSelection
            open={newRoomState.isChoosePaymentFlow}
            onCancel={closeModals}
            newRoomState={newRoomState}
            setNewRoomState={setNewRoomState}
          />
        </React.Suspense>
      )}
    </div>
  );
}

export default TariffsComponent;
